import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumb from "../components/dataschema/breadcrumb";
import { SRLWrapper } from "simple-react-lightbox";

//options for image lightbox
const options = {
  settings: {

  },
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showAutoplayButton: false,
    disablePanzoom: false,
    disableWheelControls: false,
  },
  caption: {
    showCaption: false,
  },
  thumbnails: {
    showThumbnails: true,
  }
};


const DesignPost = ({ data }) => {
  const { title, designGallery, tags, swatches, body } = data.contentfulDesign;
  // const categoryTypes = data.allContentfulBlogpostCategory.edges;


  // var officersIds = tags.map(function (officer) {
  //   return officer
  // });
  console.log(tags);
  return (
    <Layout>
      <SEO title={title} keywords={[`gatsby`, `application`, `react`]} />

      <div className="container design">
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb" className="custom-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item"><a href={typeof window !== 'undefined' ? window.location.origin + '/design/' : ''}>design</a></li>
                <li className="breadcrumb-item active" aria-current="page">{title}</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            <h1>{title}</h1>
            <div className="body-text"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            ></div>
            <h3>tags</h3>
            <div className="tags">
              {tags.map(tag => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
            <h3>swatches</h3>
            <div className="swatches">
              {swatches.map(swatch => (
                <div style={{ backgroundColor: swatch }} key={swatch}></div>
              ))}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row srlwrapper">
              <SRLWrapper options={options}>
                {designGallery.map((item, index) => (
                  <div className="col-6">
                    <a href={item.zoom.src}>
                      <img src={item.zoom.src} alt={item.title} className="img-fluid zoomImg" />
                    </a>
                  </div>
                ))}
              </SRLWrapper>
            </div>

          </div>
        </div>

      </div>
    </Layout>


  );
};
export default DesignPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulDesign(slug: { eq: $slug }) {
      title
      slug
      tags
      swatches
      designGallery {
        file {
          url
        }
        thumbs:fixed(height: 300, width: 300) {
          src
        }
        zoom:fixed(height: 1000, width: 1000) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulPageHeader(title: {eq: "life"}) {
      heroImage {
        fixed(width: 2000, height: 500) {
          src
        }
        title
      }
      title
      body {
        body
      }
    }
  }
  
`;
