import PropTypes from "prop-types"
import React from "react"
import StructuredData from 'react-google-structured-data';

function breadCrumb(seoInfo) {

  const lvl1_url = seoInfo.lvl1_url;
  const lvl1_name = seoInfo.lvl1_name;
  const lvl2_url = seoInfo.lvl2_url;
  const lvl2_name = seoInfo.lvl2_name;
  const lvl3_url = seoInfo.lvl3_url;
  const lvl3_name = seoInfo.lvl3_name;
  


    return (
        <StructuredData
        type='Organization'
        data={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": lvl1_name,
            "item": lvl1_url,
          },{
            "@type": "ListItem",
            "position": 2,
            "name": lvl2_name,
            "item": lvl2_url,
          },{
            "@type": "ListItem",
            "position": 3,
            "name": lvl3_name,
            "item": lvl3_url
          }]
        }}
    />
      )
  }

  breadCrumb.propTypes = {
   
    title: PropTypes.string,
  }
  
  breadCrumb.defaultProps = {

    title:'',
  }

export default breadCrumb;
  